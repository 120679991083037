import React from "react"
import {graphql, Link} from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HeaderSection from "../components/headerSection"
import Menu from "../components/services/menuServices"
import Preview from "../components/kaleidoscope/articlePreview"
import LeftArrow from "../assets/svg/ubuntu-kaleidoscope-arrow.svg"
import RightArrow from "../assets/svg/ubuntu-kaleidoscope-arrow-right.svg"

function Kaleidoscope (props) {
  const
    posts = props.data.posts.edges,
    {kaleidoscope, currentPage, numPages} = props.pageContext;

  return(
    <Layout>
      <SEO title="Kaleidoscope"/>

      <HeaderSection title="Kaleidoscope" description={kaleidoscope.subtitle} maxWidth="509px"/>

      <div className="articles-grid">
        {posts.map((post, i)=> {
          const
            {coverImage, intro, title, _rawEnglish, slug} = post.node

          return (
            <Preview key={i} image={coverImage} intro={intro} title={title.en} blocks={_rawEnglish || []} slug={slug}/>
          )
        })}
        <div className="nav-links">
          {currentPage !== 1 && (
            <Link className="clickable" to={`/kaleidoscope${currentPage !== 2 ? `/${currentPage - 1}` : ''}`}>
              <LeftArrow className="clickable"/> PREVIOUS PAGE
            </Link>
          )}
          {currentPage < numPages && (
            <Link className="clickable next" to={`/kaleidoscope/${currentPage + 1}`}>
              NEXT PAGE <RightArrow className="clickable"/>
            </Link>
          )}
        </div>
      </div>


      <Menu/>

    </Layout>
  )
}

export default Kaleidoscope

export const blogListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    posts: allSanityBlogPost(sort: {fields: _createdAt, order: DESC}
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          title {
            en
          }
          slug{
            current
          }
          intro
          coverImage {
            asset {
              fluid(maxWidth: 1280) {
                aspectRatio
                src
                srcSet
                srcSetWebp
                srcWebp
                sizes
              }
            }
          }
          _createdAt
          _rawEnglish
        }
      }
    }
  }
`
