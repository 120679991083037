import React from 'react'
import {Link} from 'gatsby'
import readTimeEstimate from 'read-time-estimate'

import Image from '../image'
import ArrowCTA from '../../assets/svg/ubuntu-arrow-cta.svg'

const Preview = ({image, intro, title, blocks, slug}) => {
  const defaults = {nonTextBehavior: 'remove'}

  const blocksToText= (blocks, opts = {}) => {
    const options = Object.assign({}, defaults, opts)
    return blocks
      .map(block => {
        if (block._type !== 'block' || !block.children) {
          return options.nonTextBehavior === 'remove' ? '' : `[${block._type} block]`
        }

        return block.children.map(child => child.text).join('')
      })
      .join('\n\n')
  }

  const string = blocksToText(blocks)

  return(
    <article className="preview">
      <Image fluid={image.asset.fluid} alt={`${title} | Ubuntu Experience`}/>
      <Link to={`/kaleidoscope/${slug.current}`} rel="bookmark" className="clickable">
        <div className="title-container clickable">
          <h3>{title}</h3>
          <div>
            <ArrowCTA className="clickable"/>
          </div>
        </div>
      </Link>
      <div className="info-container">
        <p>{intro}</p>
        <p>{readTimeEstimate(string, 200).humanizedDuration} read</p>
      </div>
      <div className="info-container-mobile">
        <p>{intro} / <span>{readTimeEstimate(string, 200).humanizedDuration} read</span></p>
      </div>
    </article>
  )
}

export default Preview
